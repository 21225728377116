exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-group-fitness-js": () => import("./../../../src/pages/group-fitness.js" /* webpackChunkName: "component---src-pages-group-fitness-js" */),
  "component---src-pages-inclusion-js": () => import("./../../../src/pages/inclusion.js" /* webpackChunkName: "component---src-pages-inclusion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lite-fitness-js": () => import("./../../../src/pages/lite-fitness.js" /* webpackChunkName: "component---src-pages-lite-fitness-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal-training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-support-local-js": () => import("./../../../src/pages/support-local.js" /* webpackChunkName: "component---src-pages-support-local-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

